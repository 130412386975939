import { Lang } from '@/common/types'

import TaskCompletedEn from '@/assets/images/task/task-completed-en.png'
import TaskCompletedZh from '@/assets/images/task/task-completed-zh.png'
import TaskStayTunedEn from '@/assets/images/task/task-stay-tuned-en.png'
import TaskStayTunedZh from '@/assets/images/task/task-stay-tuned-zh.png'
import TaskStayTunedTw from '@/assets/images/task/task-stay-tuned-tw.png'
import TaskAwardedEn from '@/assets/images/task/task-awarded-en.png'
import TaskAwardedZh from '@/assets/images/task/task-awarded-zh.png'
import TaskAwardedTw from '@/assets/images/task/task-awarded-tw.png'
import TaskUnlicensedEn from '@/assets/images/task/task-unlicensed-en.png'
import TaskUnlicensedZh from '@/assets/images/task/task-unlicensed-zh.png'
import TaskUnlicensedTw from '@/assets/images/task/task-unlicensed-tw.png'

const INVITE_TASK_ID = '2' // 邀请任务 id
const QUIZ_TASK_ID = '35' // 每日答题任务 id

export const KAGAWA_TASK_ID = '54'
export const HUXIAOHUI_VLOG_TASK_ID = '55'
export const LIUSHUXIAN_VLOG_TASK_ID = '56'

export const BROWSE_X = '75'
export const BROWSE_YOUTUBE = '62'

export const DAILY_BLIND_BOX = '76'

export const FORWARD_COMMENTS = '77'
export const TWITTER_RELEASES_CONTENT = '78'
export const YOUTUBE_RELEASES_CONTENT = '79'

// 需要调接口获取链接的任务 id 及对应的 linkName
const LINK_NAME_MAP = new Map([
  ['15', 'activityLink'],
  ['16', 'weiboFormLink'],
  ['30', 'bilibiliFormLink'],
  ['31', 'xiaoHongShuFormLink'],
  ['32', 'pocketFormlink'],
  ['33', 'communityFormlink'],
  ['34', 'luckyBagFormlink'],
  ['23', 'bilibilivisitLink'],
  ['25', 'youtubevisitlink'],
  ['36', 'cardCompetitionLink'],
  ['38', 'activitySecondTermLink'],
  ['37', 'qqCommunityFormLink'],
  ['13', 'discordJoinLink'],
  ['39', 'SNH48Festival2023TicketFormLink'],
  ['40', 'SNH48ShowTicketFormLink'],
  ['41', 'CardCompetitionTicketsFormLink'],
  ['42', 'activityFinalRankLink'],
  ['43', 'SNH48ShowTicket2XLink'],
  ['44', 'BeerCouponLink'],
  ['47', 'GlobalIdolRankFormLink'],
  ['53', 'VideoPostLink'],
  [KAGAWA_TASK_ID, 'HuXiangChuanLink'],
  [HUXIAOHUI_VLOG_TASK_ID, 'HuXiangChuanJapanPreviewLink'],
  [LIUSHUXIAN_VLOG_TASK_ID, 'LiuShuXianJapanPreviewLink'],
  [BROWSE_X, 'browseX'],
  [BROWSE_YOUTUBE, 'browseYoutube'],
  [FORWARD_COMMENTS, 'forwardComments'],
  [TWITTER_RELEASES_CONTENT, 'twitterReleasesContent'],
  [YOUTUBE_RELEASES_CONTENT, 'youtubeReleasesContent']
])

// 需要跳转到第三方链接的任务 ids
const LINK_FORM_IDS = [
  '16',
  '30',
  '31',
  '32',
  '33',
  '34',
  '37',
  '39',
  '40',
  '41',
  '43',
  '44',
  '47',
  '53',
  FORWARD_COMMENTS,
  TWITTER_RELEASES_CONTENT,
  YOUTUBE_RELEASES_CONTENT
]

// 需要展示 useLink 链接的任务id
const LINK_TASK_IDS = [
  '15',
  '23',
  '25',
  '36',
  '38',
  '13',
  '42',
  KAGAWA_TASK_ID,
  HUXIAOHUI_VLOG_TASK_ID,
  LIUSHUXIAN_VLOG_TASK_ID,
  BROWSE_X,
  BROWSE_YOUTUBE
]

export const APP_DAILY_LOGIN_TASK_ID = '48' // app每日登陆
export const APP_DAILY_VARIETY_SHOW_TASK_ID = '49' // app每日观看综艺
export const APP_DAILY_PERFORMANCE_TASK_ID = '50' // app每日观看公演

// 跳转了就算完成的任务id ，需要调确认接口
const VISIT_TASK_IDS = [
  '15',
  '23',
  '25',
  '36',
  '38',
  '42',
  KAGAWA_TASK_ID,
  HUXIAOHUI_VLOG_TASK_ID,
  LIUSHUXIAN_VLOG_TASK_ID,
  BROWSE_X,
  BROWSE_YOUTUBE
]

// 需要获取 twitter 最新推文id的任务id
const TWITTER_STATUS_TASK_IDS = ['8', '9', '10']

const VISIT_TASK_LABEL_MAP = new Map([
  ['15', 'task.rankSearchLink'],
  ['23', 'task.homepageLink'],
  ['25', 'task.channelAddress']
])

const ALL_TASKS = 'all'
const LUCKY_TASKS = 'luckyTasks'
const SUPPORT_TASKS = 'supportTasks'
const PROMOTIONAL_TASKS = 'promotionalTasks'
const VISITING_TASKS = 'visitingTasks'
const BIND_TASKS = 'bindTasks'
const TWITTER_TASKS = 'twitterTasks'
const DISCORD_TASKS = 'discordTasks'

const CompletedStampMap = new Map<Lang, string>([
  ['zh', TaskCompletedZh],
  ['en_US', TaskCompletedEn],
  ['zh_TW', TaskCompletedZh]
])

const StayTunedStampMap = new Map<Lang, string>([
  ['zh', TaskStayTunedZh],
  ['en_US', TaskStayTunedEn],
  ['zh_TW', TaskStayTunedTw]
])

const AwardedStampMap = new Map<Lang, string>([
  ['zh', TaskAwardedZh],
  ['en_US', TaskAwardedEn],
  ['zh_TW', TaskAwardedTw]
])

const UnlicensedStampMap = new Map<Lang, string>([
  ['zh', TaskUnlicensedZh],
  ['en_US', TaskUnlicensedEn],
  ['zh_TW', TaskUnlicensedTw]
])

export {
  CompletedStampMap,
  StayTunedStampMap,
  AwardedStampMap,
  UnlicensedStampMap,
  INVITE_TASK_ID,
  LINK_NAME_MAP,
  LINK_FORM_IDS,
  LINK_TASK_IDS,
  QUIZ_TASK_ID,
  VISIT_TASK_LABEL_MAP,
  VISIT_TASK_IDS,
  TWITTER_STATUS_TASK_IDS,
  ALL_TASKS,
  LUCKY_TASKS,
  SUPPORT_TASKS,
  PROMOTIONAL_TASKS,
  VISITING_TASKS,
  BIND_TASKS,
  TWITTER_TASKS,
  DISCORD_TASKS
}
