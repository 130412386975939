import { service } from '../http'
import {
  SaleGoods,
  VoteTransferParams,
  Team,
  Idol,
  DoVoteParams,
  VoteExchangeRecordParams,
  VoteExchangeRecord,
  VoteTransferRecordParams,
  TransferRecord,
  VoteRecordParams,
  VoteRecord,
  BlindBox,
  PacketActivityDetail,
  MyNFT,
  Packet,
  LiveInfo
} from './model'
import { PromiseResponse } from '../common'

const BASE_URL = '/activity/api/v1'

export class ActivityApi {
  // 投票券列表
  static async salesGoods(activityId: number): PromiseResponse<SaleGoods[]> {
    return service.get(`${BASE_URL}/vote/saleGoods`, { params: { activityId } })
  }
  // 查询投票券数量
  static async getUserVoteNum(activityId: number): PromiseResponse<number> {
    return service.get(`${BASE_URL}/vote/getUserVoteNum`, {
      params: { activityId }
    })
  }
  // 投票券转移
  static async voteTransfer(
    params: VoteTransferParams
  ): PromiseResponse<number> {
    return service.post(`${BASE_URL}/vote/voteTransfer`, params)
  }
  // 获取投票活动idol team列表
  static async getVoteIdolTeams(activityId: number): PromiseResponse<Team[]> {
    return service.get(`${BASE_URL}/vote/idol/getVoteIdolTeams`, {
      params: { activityId }
    })
  }
  // 获取 idol 列表
  static async getAllIdolList(activityId: number): PromiseResponse<Idol[]> {
    return service.get(`${BASE_URL}/vote/idol/getAllIdolList`, {
      params: { activityId }
    })
  }
  // 投票
  static async doVote(params: DoVoteParams): PromiseResponse<Idol[]> {
    return service.post(`${BASE_URL}/vote/doVote`, params)
  }
  // 投票券兑换记录
  static async getVoteExchangeRecord(
    params: VoteExchangeRecordParams
  ): PromiseResponse<VoteExchangeRecord[]> {
    return service.get(`${BASE_URL}/vote/getVoteExchangeRecord`, { params })
  }
  // 投票券转移记录
  static async getVoteTransferRecord(
    params: VoteTransferRecordParams
  ): PromiseResponse<TransferRecord[]> {
    return service.get(`${BASE_URL}/vote/getVoteTransferRecord`, { params })
  }
  // 投票记录
  static async getVoteRecord(
    params: VoteRecordParams
  ): PromiseResponse<VoteRecord[]> {
    return service.get(`${BASE_URL}/vote/voteRecord`, { params })
  }
  // 红包活动-获取所有盲盒
  static async getAllBlindBox(): PromiseResponse<BlindBox[]> {
    return service.get(`${BASE_URL}/packet/getAllBlindBox`)
  }
  // 红包活动-获取活动详情
  static async getActivityDetail(): PromiseResponse<PacketActivityDetail> {
    return service.get(`${BASE_URL}/packet/getActivityDetail`)
  }
  // 红包活动-我的nft数量（偶像nft，宝石）
  static async getMyNFT(): PromiseResponse<MyNFT> {
    return service.get(`${BASE_URL}/packet/myNft`)
  }
  // 红包活动-获取所有红包
  static async getAllPacket(): PromiseResponse<Packet[]> {
    return service.get(`${BASE_URL}/packet/getAllPacket`)
  }
  // 红包活动-抢红包
  static async grabPackage(packetId: string): PromiseResponse<string> {
    return service.get(`${BASE_URL}/packet/grabPackage`, {
      params: { packetId }
    })
  }
  static async getLiveInfo(): PromiseResponse<LiveInfo> {
    return service.post(`/activity/api/module/m2024/jplive/index`)
  }
  static async buyLiveTicket(): PromiseResponse<LiveInfo> {
    return service.post(`/activity/api/module/m2024/jplive/ticket/buy`)
  }
  // 统计播放次数
  static async livePlayCount(streamName: string): PromiseResponse<void> {
    return service.post(
      `/activity/api/module/m2024/jplive/statistics/play_count?streamName=${streamName}`
    )
  }
  // 红包活动2-获取参与次数
  static async getDrawCount(): PromiseResponse<{ count: string }> {
    return service.post(`${BASE_URL}/packet/drawRedEnvelopesCount`)
  }
  // 红包活动2-抢红包
  static async draw(): PromiseResponse<{ count: string }> {
    return service.post(`${BASE_URL}/packet/drawRedEnvelopes`)
  }
}
