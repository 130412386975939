import React from 'react'
import styles from './style.module.less'
import LangChange from '../LangChange'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { taEnterPageTrack } from '@/common/thinkingdata'
import useModal from '@/hooks/useModal'
import { useUserInfo } from '@/store/useUserInfo'
import Login from '@/components/LoginModal/Login'
import useHandleHistory from '@/hooks/useHandleHistory'

type Route = {
  title: JSX.Element | string
  paths?: string[]
  key: string
  onClick?: () => void
}

const Navigate: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { push } = useHandleHistory()

  const ROUTES: Route[] = [
    { title: 'head.gipr', paths: ['/gipr', '/'], key: 'gipr' },
    // { title: 'head.live', paths: ['/live'], key: 'live' },
    {
      title: (
        <div className={styles.colorfulText}>{t('redEnvelope.activity')}</div>
      ),
      paths: ['/activity'],
      key: 'activity'
    },
    { title: 'head.task', paths: ['/task'], key: 'task' },
    { title: 'head.shop', paths: ['/shop'], key: 'shop' },
    { title: 'dazzle.dazzle', paths: ['/dazzle'], key: 'dazzle' }
  ]

  return (
    <div className={styles.navigate}>
      <a
        href={process.env.REACT_APP_MEET48_WEBSITE}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        HOME
      </a>
      {ROUTES.map((item) => {
        const isActive = item.paths?.includes(pathname)
        return (
          <div
            key={item.key}
            onClick={() => {
              if (item.paths) {
                push(item.paths[0])
                taEnterPageTrack(item.paths[0], '导航栏')
              }
              if (item.onClick) {
                item.onClick()
              }
            }}
            className={classNames(styles.link, isActive && styles.activeLink)}
          >
            {typeof item.title === 'string' ? t(item.title) : item.title}
          </div>
        )
      })}
      <LangChange />
    </div>
  )
}

export default React.memo(Navigate)
