import { service } from '../http'
import { paramAddChain } from '@/utils/utils'
import {
  LoginWeb3Params,
  LoginWeb2Params,
  UserInfo,
  RegisterParams,
  PublicKey,
  ForgetPasswordParams,
  LogoutParams,
  CheckEmailRegisterParams,
  UserAsset,
  NftExchangeRecord,
  ReceivedPointRecord,
  TransferTicketRecord,
  ExchangeTicketParams,
  VoteForIdolParams,
  VoteRecord,
  UserInviteRecord,
  PendingPoints,
  UserAndAllPointRank,
  GetUserInfoParams,
  UpdateAccountInfoParams,
  UpdateAvatarParams,
  UpdateAvatarData,
  UpdateAccountPasswordParams,
  UpdateWeb3EmailParams,
  UpdateAccountEmailParams,
  GetPointToNftListParams,
  HKTicket,
  GetOrderListParams,
  BuyTicketParams,
  TicketBuyRecord,
  ExchangeItem,
  PageParams,
  ActivityParticipateParams,
  ActivityAvailableBalanceParams,
  PointExchangeListQuery,
  RankingListResult,
  TransferVotesParams,
  VoteRecordParams,
  TransferTicketRecordsParams,
  NFTExchangeRecordParams,
  UserViewRankResult,
  SupportCoin,
  AddUserWithdrawAddressParams,
  GetUserWithdrawAddressListParams,
  Address,
  Project,
  DeleteUserWithdrawAddressParams,
  AssetDetail,
  WithdrawParams,
  InternalTransferParams,
  WithdrawVerificationMethod,
  DepositAddress,
  UserAssetDetail,
  NFTDepositAddress,
  GetAssetListParams,
  GetDepositRecordParams,
  DepositRecordResult,
  GetWithdrawRecordParams,
  WithdrawRecordResult,
  AddressType,
  GetWithdrawVerificationMethodParams,
  IsValidParams
} from './model'
import { PromiseResponse, BaseParams, PromiseListResponse } from '../common'
import { RecaptchaActions, getGoogleRecaptchaToken } from '@/common/login'
import { errorNotice } from '@/common/tip'
import { EncryptData } from '../MediaAPI'

const BASE_URL = '/wallet/api/v1'

export class WalletApi {
  // 获取打call排行榜列表
  static async getRankingList(
    periods: number | string
  ): PromiseResponse<RankingListResult> {
    return await service.post(`${BASE_URL}/userExpand/getUsersCallRank`, {
      periods
    })
  }
  // 获取打call排行榜列表
  static async getUserViewRank(): PromiseResponse<UserViewRankResult> {
    return await service.get(`${BASE_URL}/user/getUserViewRank`, {
      params: {
        pageNo: 1,
        pageSize: 100
      }
    })
  }
  // Web3登录
  static async mutateLoginWeb3(
    params: LoginWeb3Params
  ): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/metaMaskLogin`, data)
  }
  // 获取验证码
  // type=1表示注册、2表示修改密码、3表示更改邮箱原邮箱、4表示更改邮箱的新邮箱
  static async getCodeFromEmail(
    email: string,
    type = `1`
  ): PromiseResponse<void> {
    let token = ''
    try {
      token = await getGoogleRecaptchaToken(RecaptchaActions.sendEmail)
    } catch (error: any) {
      errorNotice(error)
    }
    const params = new URLSearchParams()
    params.append('email', email)
    params.append('type', type)
    params.append('googleRecaptchaToken', token ?? '')
    params.append('googleActionType', RecaptchaActions.sendEmail)
    return service.get(`${BASE_URL}/user/sendEmail?${params.toString()}`)
  }
  // 注册_web2
  static async registerWeb2(params: RegisterParams): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/register`, data)
  }
  // 登录_web2
  static async loginWeb2(params: LoginWeb2Params): PromiseResponse<UserInfo> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/login`, data)
  }
  // 加密公钥
  static async getPublicKey(): PromiseResponse<PublicKey> {
    return service.post(`${BASE_URL}/user/getPublicKey`)
  }
  // 忘记密码
  static async forgetPassword(
    params: ForgetPasswordParams
  ): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/forgotPassword`, data)
  }
  // 退出登录
  static async logOut(params: LogoutParams): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/user/logout`, data)
  }
  // 验证邮箱是否注册
  static async checkMailRegi(
    params: CheckEmailRegisterParams
  ): PromiseResponse<boolean> {
    return service.post(`${BASE_URL}/userExpand/checkMailRegister`, params)
  }
  // 获取用户资产
  static async getUserAsset(accountId: string): PromiseResponse<UserAsset[]> {
    return service.post(`${BASE_URL}/user/getAssetDetail`, { accountId })
  }
  // 积分兑换记录
  static async queryNftExchangeRecord(
    params: NFTExchangeRecordParams
  ): PromiseListResponse<{ list: NftExchangeRecord[] }> {
    return service.post(`${BASE_URL}/userExpand/getNftExchangeRecords`, params)
  }
  // 积分明细
  static async queryPointRecords({
    pageNum,
    pageSize
  }: PageParams): PromiseResponse<{ pointsList: ReceivedPointRecord[] }> {
    return service.post(`${BASE_URL}/userExpand/getReceivedPointRecords`, {
      page: pageNum,
      pageSize
    })
  }
  // 获取用户的投票券转账记录
  static async queryTransferTicketRecords(
    params: TransferTicketRecordsParams
  ): PromiseResponse<TransferTicketRecord[]> {
    return service.post(`${BASE_URL}/userExpand/transferTicketRecords`, params)
  }
  // 获取积分兑换列表
  static async queryPointExchangeList(
    params: PointExchangeListQuery
  ): PromiseResponse<ExchangeItem[]> {
    return service.post(`${BASE_URL}/pointsMall/getPointExchangeList`, params)
  }
  // 兑换投票券
  static async mutateExchangeTicket(
    params: ExchangeTicketParams
  ): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/exchangeTicket`, data)
  }
  // 转账投票券
  static async mutateTransferVotes(params: TransferVotesParams) {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/transferVotes`, data)
  }
  // 获取免费测试币
  static async queryFreeTestCoin(params?: any) {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getFreeTestCoin`, data)
  }
  // 偶像投票
  static async mutateVoteForIdol(
    params: VoteForIdolParams
  ): PromiseResponse<void> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/ticketVote/voteForIdol`, data)
  }
  // 获取用户投票记录
  static async queryVoteRecords(
    params: VoteRecordParams
  ): PromiseListResponse<{ list: VoteRecord[] }> {
    return service.post(`${BASE_URL}/userExpand/getVoteRecords`, params)
  }
  // 邀请记录
  static async queryInviteRecords(
    params: BaseParams
  ): PromiseResponse<UserInviteRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/userInviteRecords `, data)
  }
  // 邀请任务积分信息
  static async queryInviteTaskPoints(
    params: BaseParams
  ): PromiseResponse<PendingPoints> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getPendingPoints `, data)
  }
  // 获取用户的积分排名和和全部前50排名
  static async queryUserPointRank(
    params: BaseParams
  ): PromiseResponse<UserAndAllPointRank> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/userExpand/getUserAndAllPointRank `, data)
  }
  // 获取用户信息
  static async getUserInfo(
    params: GetUserInfoParams
  ): PromiseResponse<UserInfo> {
    return service.post(`${BASE_URL}/userExpand/getUserInfo`, params)
  }
  // 修改用户信息
  static async updateAccountInfo(
    params: Partial<UpdateAccountInfoParams>
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountInfo`, params)
  }
  // 上传头像
  static async uploadAvatar(
    params: UpdateAvatarParams
  ): PromiseResponse<UpdateAvatarData> {
    return service.post(`${BASE_URL}/userExpand/uploadAvatar`, params)
  }
  // 修改密码
  static async updateAccountPassword(
    params: UpdateAccountPasswordParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountPassword`, params)
  }
  // 设置邮箱
  static async updateWeb3Email(
    params: UpdateWeb3EmailParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateWeb3Email`, params)
  }
  // 修改邮箱
  static async updateAccountEmail(
    params: UpdateAccountEmailParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/userExpand/updateAccountEmail`, params)
  }
  // 商城-获取门票列表
  static async getPointToNftList(
    params: GetPointToNftListParams
  ): PromiseResponse<HKTicket[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/getPointToNftList`, data)
  }
  // 商城-购买门票
  static async buyTicket(params: BuyTicketParams): PromiseResponse<string[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/summitBuy`, data)
  }
  // 商城-查询订单
  static async getTicketOrderList(
    params: GetOrderListParams
  ): PromiseResponse<TicketBuyRecord[]> {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/pointsMall/getOrderList`, data)
  }
  // 商城-查询是否有订单在队列里排队
  static async getTicketTaskHandling(userId: number): PromiseResponse<boolean> {
    return service.post(`${BASE_URL}/pointsMall/getHandlerStatus`, { userId })
  }
  static async participateActivity(
    params: ActivityParticipateParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/pointsMall/participateActivity`, params)
  }
  static async getActivityAvailableBalance(
    params: ActivityAvailableBalanceParams
  ): PromiseResponse<number> {
    return service.post(
      `${BASE_URL}/pointsMall/getActivityAvailableBalance`,
      params
    )
  }
  static async checkArea(): PromiseResponse<number> {
    return service.post(`${BASE_URL}/user/checkArea`)
  }
  static async getCoinSupportList(
    type?: 'Withdraw' | 'Deposit'
  ): PromiseResponse<SupportCoin[]> {
    return service.get(`${BASE_URL}/coin/supportList`, { params: { type } })
  }
  static async addOrUpdateUserWithdrawAddress(
    params: AddUserWithdrawAddressParams
  ): PromiseResponse<number> {
    return service.post(`${BASE_URL}/userWithdrawAddress/saveOrUpdate`, params)
  }
  static async getUserWithdrawAddressList(
    params: GetUserWithdrawAddressListParams
  ): PromiseListResponse<{ records: Address[] }> {
    return service.get(`${BASE_URL}/userWithdrawAddress/page`, { params })
  }
  static async getAddressSource(sourceType: number): PromiseResponse<string[]> {
    return service.get(`${BASE_URL}/coin/getAddressSource`, {
      params: { sourceType }
    })
  }
  static async getAllUserWithdrawAddressList(
    chainId?: number
  ): PromiseResponse<Address[]> {
    return service.get(`${BASE_URL}/userWithdrawAddress/list`, {
      params: { chainId }
    })
  }
  static async deleteUserWithdrawAddress(
    params: DeleteUserWithdrawAddressParams
  ): PromiseResponse<void> {
    const ids = params.id.map((item) => `id=${item}`).join('&')
    const query = `${ids}&emailCode=${params.emailCode}&authCode=${params.authCode}`
    return service.delete(`${BASE_URL}/userWithdrawAddress/delete?${query}`)
  }
  static async getDepositAddress(
    chainCode: string
  ): PromiseResponse<DepositAddress> {
    return service.get(
      `${BASE_URL}/account/depositAddress?chainName=${chainCode}`
    )
  }
  static async getProjectList(): PromiseResponse<Project[]> {
    return service.get(`${BASE_URL}/project/list`)
  }
  // 查询用户某币种资产信息
  static async getAssetDetail(coinCode: string): PromiseResponse<AssetDetail> {
    return service.get(`${BASE_URL}/account/asset/detail`, {
      params: { coinCode }
    })
  }
  // 提币申请
  static async withdraw(params: WithdrawParams): PromiseResponse<void> {
    return service.post(`${BASE_URL}/withdraw/withdraw`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  // 内部地址转账
  static async internalTransfer(
    params: InternalTransferParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/withdraw/internalTransfer`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  // 提现条件可以配置，这里获取提现条件
  static async getWithdrawVerificationMethod(
    params: GetWithdrawVerificationMethodParams
  ): PromiseResponse<WithdrawVerificationMethod> {
    return service.get(`${BASE_URL}/withdraw/verificationMethod`, {
      params
    })
  }
  // 查询用户总资产
  static async getAssetTotal(): PromiseResponse<UserAssetDetail> {
    return service.get(`${BASE_URL}/account/asset/total`)
  }
  // 获取用户NFT充值地址
  static async getNFTDepositAddress(): PromiseResponse<NFTDepositAddress> {
    return service.get(`${BASE_URL}/account/nftDepositAddress`)
  }
  // 查询用户资产列表
  static async getAssetList(
    params: GetAssetListParams
  ): PromiseResponse<EncryptData> {
    return service.post(`${BASE_URL}/account/asset/list`, params)
  }
  // 获取用户充值记录
  static async getDepositRecord(
    params: GetDepositRecordParams
  ): PromiseResponse<DepositRecordResult> {
    return service.post(`${BASE_URL}/account/assetChangeRecord`, params)
  }
  // 获取用户提币记录
  static async getWithdrawRecord(
    params: GetWithdrawRecordParams
  ): PromiseResponse<WithdrawRecordResult> {
    return service.post(`${BASE_URL}/withdraw/withdrawRecord`, params)
  }
  // 判断提现地址是否为内部托管地址
  static async getAddressType(address: string): PromiseResponse<AddressType> {
    return service.get(
      `${BASE_URL}/userWithdrawAddress/getAddressType?address=${address}`
    )
  }
  static async isValid(params: IsValidParams): PromiseResponse<void> {
    return service.get(`${BASE_URL}/user/isValid`, { params })
  }
  static async isAddressValid(params: IsValidParams): PromiseResponse<void> {
    return service.get(`${BASE_URL}/userWithdrawAddress/isValid`, { params })
  }
}
