import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import {
  Description,
  ExistAddressSelect,
  GoBackTitle,
  Page
} from '../components'
import { NFT_MANAGE } from '@/constants/profile'
import { Form } from 'antd'
import InputBox from '@/components/InputBox'
import Tabs from '@/components/Tabs'
import type { TabsProps } from 'antd'
import Select from '@/components/Select'
import { getLangData, getMediaSrc, getSearchParams } from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import {
  AssetDetail,
  WalletApi,
  WithdrawSafeVerificationParams
} from '@/services/wallet'
import Module from '@/components/Module'
import { Button } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { errorNotice, notice } from '@/common/tip'
import { useSafeVerification } from '@/store/useSafeVerification'
import { checkWithdrawConditions } from '@/common/verify'
import { UserAPI } from '@/services/userAPI'
import { DefaultNFT } from '../image'
import { useTranslation } from 'react-i18next'

const { LabelInput } = InputBox

const DEFAULT_ASSET: AssetDetail = {
  withdrawAble: 0,
  available: 0
}

const NFTWithdraw: React.FC = () => {
  const { t } = useTranslation()
  const { nftId, tokenId } = getSearchParams()
  const [form] = Form.useForm()
  const [withdrawType, setWithdrawType] = useState('1')
  const history = useHistory()
  const { setCallback } = useSafeVerification()
  const [assetDetail, setAssetDetail] = useState(DEFAULT_ASSET)

  const { data } = useQuery(() =>
    UserAPI.getNFTDetail({
      id: nftId,
      tokenId: tokenId
    })
  )

  useEffect(() => {
    async function fetch() {
      const res = await WalletApi.getAssetDetail(data!.feeTokenName)
      if (res.data.code === 0) {
        setAssetDetail(res.data.data)
      } else {
        errorNotice(res.data.message)
      }
    }
    if (data?.feeTokenName) {
      fetch()
    }
  }, [data?.feeTokenName])

  useEffect(() => {
    if (data) {
      form.setFieldValue('chainCode', data.chainCode)
    }
  }, [data])

  const name = getLangData(data?.goodsNameI18n)
  const src = getMediaSrc(data?.resources[0]?.resourceUrl) || DefaultNFT

  const { data: projectList = [] } = useQuery(WalletApi.getProjectList)

  const formTabs: TabsProps['items'] = [
    {
      key: '1',
      label: t('normal.address'),
      children: (
        <div className={styles.addressForm}>
          <Form.Item noStyle name="toAddress">
            <ExistAddressSelect chainId={data?.chainId} />
          </Form.Item>
          <Form.Item noStyle name="chainCode">
            <LabelInput
              label={t('wallet.assets.withdrawalNetwork')}
              placeholder={t('wallet.assets.withdrawalNetwork')}
              disabled
            />
          </Form.Item>
          <div className={styles.info}>
            <Description
              label={t('wallet.assets.networkFee')}
              desc={`${data?.withdrawFee ?? 0} ${data?.feeTokenName ?? ''}`}
            />
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: t('wallet.assets.internal'),
      children: (
        <div className={styles.addressForm}>
          <Form.Item noStyle name="toUPid">
            <Select
              options={projectList.map((project) => ({
                label: project.projectName,
                value: project.id
              }))}
              placeholder={t('wallet.assets.withdrawProduct')}
            />
          </Form.Item>
          <Form.Item noStyle name="toUserCode">
            <LabelInput label="" placeholder={t('wallet.assets.recipientId')} />
          </Form.Item>
        </div>
      )
    }
  ]

  function verifyForm() {
    const values = form.getFieldsValue()
    if (!nftId) {
      notice(t('wallet.form.nftIdNotExist'))
      return false
    }
    if (!data?.coinCode) {
      notice(t('wallet.form.coinCodeNotExist'))
      return false
    }
    if (withdrawType === '1') {
      // 地址提现
      if (!values.toAddress) {
        notice(t('wallet.form.selectWithdrawalAddressTip'))
        return false
      }
      if (!values.chainCode) {
        notice(t('wallet.form.selectNetworkTip'))
        return false
      }
      if (+data!.withdrawFee > assetDetail.available) {
        notice(t('normal.insufficientBalance'))
        return false
      }
    } else {
      if (!values.toUPid) {
        notice(t('wallet.form.projectSelectTip'))
        return false
      }
      if (!values.toUserCode) {
        notice(t('wallet.form.recipientIdTip'))
        return false
      }
    }
    return true
  }

  async function checkAddressType() {
    const { toAddress } = form.getFieldsValue()
    const res = await WalletApi.getAddressType(toAddress)
    if (res.data.code === 0) {
      return res.data.data.addressType === 0
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function withdraw() {
    const isHostingAddress = await checkAddressType()
    if (!isHostingAddress) {
      notice(t('wallet.form.unableTransferToSelf'))
      return
    }
    const { chainCode, toAddress } = form.getFieldsValue()
    const formData = {
      tokenId: data!.tokenId,
      amount: 1,
      coinCode: data!.coinCode,
      toAddress,
      chainCode,
      nftId
    }
    setCallback(async function (params: WithdrawSafeVerificationParams) {
      const res = await WalletApi.withdraw({ ...params, ...formData })
      if (res.data.code === 0) {
        notice(t('normal.submitSuccess'), 'success')
        history.push('/profile/assets')
      } else {
        errorNotice(res.data.message)
      }
    })
    history.push('/profile/nft-withdraw-safety-verification')
  }

  async function internalTransfer() {
    const { toUserCode, toUPid } = form.getFieldsValue()
    const formData = {
      amount: 1,
      coinCode: data!.coinCode,
      toUserCode,
      toUPid,
      nftId
    }
    setCallback(async function (params: WithdrawSafeVerificationParams) {
      const res = await WalletApi.internalTransfer({ ...params, ...formData })
      if (res.data.code === 0) {
        notice(t('normal.submitSuccess'), 'success')
        history.push('/profile/assets')
      } else {
        errorNotice(res.data.message)
      }
    })
    history.push('/profile/nft-withdraw-safety-verification')
  }

  async function checkAddressValid() {
    const { toAddress } = form.getFieldsValue()
    const chainId = data?.chainId
    const res = await WalletApi.isAddressValid({ address: toAddress, chainId })
    if (res.data.code === 0) {
      return true
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function checkValid() {
    const { toUserCode } = form.getFieldsValue()
    const res = await WalletApi.isValid({ toUserCode })
    if (res.data.code === 0) {
      return true
    } else {
      errorNotice(res.data.message)
      return false
    }
  }

  async function submit() {
    const bound = await checkWithdrawConditions()
    if (!bound) {
      return
    }
    if (!verifyForm()) {
      return
    }
    if (withdrawType === '1') {
      const isValid = await checkAddressValid()
      if (!isValid) {
        return
      }
      withdraw()
    } else {
      const isValid = await checkValid()
      if (!isValid) {
        return
      }
      internalTransfer()
    }
  }

  return (
    <Page navigator={NFT_MANAGE}>
      <Module>
        <GoBackTitle title={t('wallet.nft.withdrawalNFT')} />
        <div className={styles.title}>
          {t('wallet.nft.nftWithdrawQuantity')}
        </div>
        <img className={styles.img} src={src} />
        <div className={styles.name}>{name}</div>
        <Form form={form}>
          <div className={styles.form}>
            <Tabs
              items={formTabs}
              className={styles.tabs}
              activeKey={withdrawType}
              onChange={(key) => setWithdrawType(key)}
            />
            <Button className={styles.btn} disabled={!data} onClick={submit}>
              {t('wallet.assets.nextStep')}
            </Button>
          </div>
        </Form>
        <div className={styles.tip}>
          <div>{t('wallet.nft.note')}</div>
          <div>{t('wallet.nft.noteDesc1')}</div>
          <div>{t('wallet.nft.noteDesc2')}</div>
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(NFTWithdraw)
