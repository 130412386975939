import React, { useEffect, lazy, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Profile from '@/views/Profile'
import AddressManage from '@/views/Profile/AddressManage'
import AddAddress from '@/views/Profile/AddAddress'
import ProfileAssets from '@/views/Profile/Assets'
import Recharge from '@/views/Profile/Recharge'
import CurrencyRecord from '@/views/Profile/CurrencyRecord'
import CurrencyDetail from '@/views/Profile/CurrencyDetail'
import PointRecord from '@/views/Profile/PointRecord'
import NFTRecord from '@/views/Profile/NFTRecord'
import OtherRecord from './Profile/OtherRecord'
import Setting from './Profile/Setting'
import SetAuthenticator from './Profile/SetAuthenticator'
import SetFundsPassword from './Profile/SetFundsPassword'
import Withdraw from './Profile/Withdraw'
import NFTWithdraw from './Profile/NFTWithdraw'
import WithdrawSafetyVerification from './Profile/WithdrawSafetyVerification'
import NFTWithdrawSafetyVerification from './Profile/NFTWithdrawSafetyVerification'
import NFTDetail from './Profile/NFTDetail'
import NFT from './Profile/NFT'
import Items from './Profile/Items'
import ItemDetail from './Profile/ItemDetail'
import SafetyVerification from './Profile/SafetyVerification'
import UpdateEmail from './Profile/UpdateEmail'
import UpdatePassword from './Profile/UpdatePassword'
import DazzleManage from './Profile/DazzleManage'
import Draft from './Profile/Draft'

import Header from '@/components/Header'
import ModalsProvider from '../contexts/Modals'
import { useInviteCode } from 'hooks/useInviteCode'
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useUserInfo } from '@/store/useUserInfo'
import { useMediaInfo } from '@/store/useMediaInfo'
import { WalletApi } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import styles from './App.module.less'
import { useAssets } from '@/store/useAssets'
import { X_DEVICE_ID } from '@/config'
import { useDownload } from '@/store/useDownload'
import { useEnvInfo } from '@/store/useEnvInfo'
import {
  getLocalItem,
  getSessionItem,
  setLocalItem,
  setSessionItem
} from '@/common/storage'
import { taInit } from '@/common/thinkingdata'

import { GLOBAL_CLICK_TYPE, useEvents } from '@/store/useEvents'

import GlobalBroadcast from '@/components/GlobalBroadcast'
import { useIdols } from '@/store/useIdols'
import { VOTE_DATA } from '@/constants/vote'
import { config as wagmiConfig } from '@/config/wagmi'
import NFTTransferRecords from './Profile/NFTTransferRecords'

const queryClient = new QueryClient()

async function getPublicKey() {
  const res = await WalletApi.getPublicKey()
  if (res.data.code === 0) {
    setSessionItem('PublicKey', res.data.data.publicKey)
  } else {
    errorNotice(res.data.message)
  }
}

const LazyGIPR = lazy(() => import('./GIPR'))
const LazyHome = lazy(() => import('./Home'))
const LazyHomeRanking = lazy(() => import('./HomeRanking'))
const LazyTask = lazy(() => import('./Task'))
const LazyShop = lazy(() => import('./Shop'))
const LazyAppIntro = lazy(() => import('./AppIntro'))
const LazyDazzle = lazy(() => import('./Dazzle'))
const LazyDazzleDetail = lazy(() => import('./DazzleDetail'))
const LazyRedEnvelopes = lazy(() => import('./RedEnvelopes'))
const LazyLive = lazy(() => import('./Live'))
// const LazyDazzleUpload = lazy(() => import('./DazzleUpload'))

const App: React.FC = () => {
  const { initInviteCode } = useInviteCode()
  const { info } = useUserInfo()
  const { resetMediaInfo, fetchMediaInfo } = useMediaInfo()
  const { getAssets } = useAssets()
  const { getIdols } = useIdols()
  const { getURLs } = useDownload()
  useEnvInfo()

  useEffect(() => {
    if (!info) {
      resetMediaInfo()
    } else {
      fetchMediaInfo(info.accountId)
    }
  }, [info?.accountId])

  useEffect(() => {
    if (info?.accountId) {
      getAssets(info.accountId)
    }
  }, [info?.accountId])

  function init() {
    // 获取下载页相关链接和二维码地址
    getURLs()

    // 获取公钥
    if (!getSessionItem('PublicKey')) {
      getPublicKey()
    }

    // 初始化邀请码
    initInviteCode()

    // 获取设备id
    const deviceId = getLocalItem(X_DEVICE_ID)
    if (!deviceId) {
      setLocalItem(X_DEVICE_ID, 'PC')
    }

    // 初始化thinking data
    taInit()

    // 获取idols
    getIdols(VOTE_DATA.activityId)
  }

  useEffect(init, [])

  const { execEvents } = useEvents()

  useEffect(() => {
    function handle() {
      execEvents(GLOBAL_CLICK_TYPE)
    }
    document.addEventListener('click', handle, false)
    return () => {
      document.removeEventListener('click', handle)
    }
  }, [])

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.wrapper}>
          <HashRouter>
            <ModalsProvider>
              <div className={styles.layout}>
                <GlobalBroadcast />
                <Header />
                <Suspense>
                  <Switch>
                    <Route path="/" component={LazyHomeRanking} exact />
                    <Route path="/gipr" component={LazyHomeRanking} exact />
                    <Route
                      path="/activity"
                      component={LazyRedEnvelopes}
                      exact
                    />
                    {/* <Route path="/live" component={LazyLive} exact /> */}
                    <Route path="/vote" component={LazyGIPR} exact />
                    <Route path="/task" component={LazyTask} exact />
                    <Route path="/shop" component={LazyShop} exact />
                    <Route path="/app" component={LazyAppIntro} exact />
                    <Route path="/dazzle" component={LazyDazzle} exact />
                    <Route
                      path="/dazzle-detail"
                      component={LazyDazzleDetail}
                      exact
                    />
                    {/* <Route
                      path="/dazzle-upload"
                      component={LazyDazzleUpload}
                      exact
                    /> */}
                    <Route path="/personal" component={Profile} exact />
                    <Route
                      path="/profile/address-manage"
                      component={AddressManage}
                      exact
                    />
                    <Route
                      path="/profile/add-address"
                      component={AddAddress}
                      exact
                    />
                    <Route
                      path="/profile/assets"
                      component={ProfileAssets}
                      exact
                    />
                    <Route
                      path="/profile/recharge"
                      component={Recharge}
                      exact
                    />
                    <Route
                      path="/profile/currency-record"
                      component={CurrencyRecord}
                      exact
                    />
                    <Route
                      path="/profile/withdraw"
                      component={Withdraw}
                      exact
                    />
                    <Route
                      path="/profile/nft-withdraw"
                      component={NFTWithdraw}
                      exact
                    />
                    <Route
                      path="/profile/currency-detail"
                      component={CurrencyDetail}
                      exact
                    />
                    <Route
                      path="/profile/video-manage"
                      component={DazzleManage}
                      exact
                    />
                    <Route path="/profile/draft" component={Draft} exact />
                    <Route
                      path="/profile/point-record"
                      component={PointRecord}
                      exact
                    />
                    <Route
                      path="/profile/nft-record"
                      component={NFTRecord}
                      exact
                    />
                    <Route
                      path="/profile/other-record"
                      component={OtherRecord}
                      exact
                    />
                    <Route
                      path="/profile/nft-transfer-record"
                      component={NFTTransferRecords}
                      exact
                    />
                    <Route path="/profile/setting" component={Setting} exact />
                    <Route
                      path="/profile/set-funds-password"
                      component={SetFundsPassword}
                      exact
                    />
                    <Route
                      path="/profile/set-authenticator"
                      component={SetAuthenticator}
                      exact
                    />
                    <Route path="/profile/nft" component={NFT} exact />
                    <Route path="/profile/items" component={Items} exact />
                    <Route
                      path="/profile/nft-detail"
                      component={NFTDetail}
                      exact
                    />
                    <Route
                      path="/profile/item-detail"
                      component={ItemDetail}
                      exact
                    />
                    <Route
                      path="/profile/safety-verification"
                      component={SafetyVerification}
                      exact
                    />
                    <Route
                      path="/profile/withdraw-safety-verification"
                      component={WithdrawSafetyVerification}
                      exact
                    />
                    <Route
                      path="/profile/nft-withdraw-safety-verification"
                      component={NFTWithdrawSafetyVerification}
                      exact
                    />
                    <Route
                      path="/profile/update-email"
                      component={UpdateEmail}
                      exact
                    />
                    <Route
                      path="/profile/update-password"
                      component={UpdatePassword}
                      exact
                    />
                  </Switch>
                </Suspense>
              </div>
            </ModalsProvider>
          </HashRouter>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App
